import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/Layout";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default () => {
  const { thanksImage } = useStaticQuery(graphql`
    query {
      thanksImage: file(relativePath: { eq: "thanks.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Layout whiteHeader>
      <section>
        <div className="container">
          <div className="image-container">
            <PreviewCompatibleImage
              imageInfo={thanksImage}
              alt="thanks image"
            />
            <h1>Thanks for contacting us!</h1>
            <p>We will get back to you soon.</p>
          </div>
        </div>
        <style jsx>{`
          section {
            padding: 32px;
          }

          .container {
            max-width: 1278px;
            margin: 0 auto;
          }

          .image-container {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            text-align: center;
            font-family: "Open Sans", sans-serif;
          }

          h1 {
            margin: 0;
          }

          p {
            margin: 8px auto;
            max-width: 400px;
          }
        `}</style>
      </section>
    </Layout>
  );
};
